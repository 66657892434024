<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-date-picker
          class="vg_ml_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="装运开始日期"
          end-placeholder="装运结束日期"
        >
        </el-date-picker>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getStatsList()" class="vg_ml_16">查询 </el-button>
        <el-button type="info" size="small" class="vd_export" @click="refresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="primary" size="small" @click="printClick">打印</el-button>
        <el-button type="success" size="small" @click="exportToExcel('allTable')">导出到Excel</el-button>
        <el-button type="danger" size="small" @click="showOperationDialog">数据操作</el-button>
        <el-button type="primary" size="small" @click="confirmModifications">确认修改</el-button>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'ofte_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @row-click="prodNoChaange"
        @getTableData="getStatsList"
      >
        <template v-slot:commission_rate="scope">
          <el-input size="mini" v-model="scope.row.commission_rate" @input="val => keep1Decimal(val)" :maxlength="3"></el-input>
        </template>
      </search-table>
      <el-dialog title="对选中的数据如何处理?" center @closed="getStatsList" :visible.sync="operationDialogVisible" width="70%">
        <search-table :columns="operationTableProperties" :data="multiSelection" :need-pagination="false" :need-search="false" :tableRowKey="'operation'">
          <template v-slot:operation="scope">
            <el-button v-if="scope.row.operation_status === 0" plain size="mini" type="danger" @click="operationRequest(scope.row, 1)">结束保存</el-button>
            <el-button v-if="scope.row.operation_status === 1" plain size="mini" type="primary" @click="operationRequest(scope.row, 0)">撤销结束</el-button>
          </template>
        </search-table>
      </el-dialog>

      <el-row>
        <el-col :md="24">
          <el-tabs v-model="activeName">
            <el-tab-pane label="收款记录" name="first" lazy>
              <CoreList v-if="activeName === 'first'" :tableData="CoreList" />
            </el-tab-pane>
            <el-tab-pane label="付款记录" name="third" lazy>
              <PacoList v-if="activeName === 'third'" :tableData="PacoList" />
            </el-tab-pane>
            <el-tab-pane label="包干费" name="fourth" lazy>
              <PaprList v-if="activeName === 'fourth'" :tableData="PaprList" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
        <el-button @click="printC">打印</el-button>
        <div id="printMe" class="printc">
          <table border="1px" width=" 100%" cellpadding="0" cellspacing="0" class="vd_table printd">
            <tr>
              <th class="vd_th">出运发票号</th>
              <th class="vd_th">客户简称</th>
              <th class="vd_th">品名</th>
              <th class="vd_th">实收外汇(美元)</th>
              <th class="vd_th vd_borrn">折人民币</th>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.cust_abbr }}
              </td>
              <td class="vd_td_t">
                {{ item.prod_hsnames }}
              </td>
              <td class="vd_td_t">
                {{ item.rece_total }}
              </td>
              <td class="vd_td_t vd_borrn">
                {{ item.conv_rmb }}
              </td>
            </tr>
          </table>
          <table border="1px" width=" 100%" cellpadding="0" cellspacing="0" class="vd_table printd">
            <tr>
              <th class="vd_th">扣佣金(美元)</th>
              <th class="vd_th">扣佣金后实收美金(美元)</th>
              <th class="vd_th">买单汇率</th>
              <th class="vd_th">扣佣金后实收人民币</th>
              <th class="vd_th">工厂货款</th>
              <th class="vd_th">退税额</th>
              <th class="vd_th vd_borrn">毛利1</th>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <td class="vd_td_t">
                {{ item.deduct_comm }}
              </td>
              <td class="vd_td_t">
                {{ item.paid_in_usd }}
              </td>
              <td class="vd_td_t">
                {{ item.purchase_rate }}
              </td>
              <td class="vd_td_t">
                {{ item.paid_in_rmb }}
              </td>
              <td class="vd_td_t">
                {{ item.factory_loan }}
              </td>
              <td class="vd_td_t">
                {{ item.tax_refund }}
              </td>
              <td class="vd_td_t vd_borrn">
                {{ item.gross_profit_one }}
              </td>
            </tr>
          </table>
          <table border="1px" width=" 100%" cellpadding="0" cellspacing="0" class="vd_table printd">
            <tr>
              <th class="vd_th">人民币包干费</th>
              <th class="vd_th">代理费(人民币)</th>
              <th class="vd_th">毛利2</th>
              <th class="vd_th">毛利率</th>
              <th class="vd_th">换汇成本</th>
              <th class="vd_th">采购部6%服务费</th>
              <th class="vd_th vd_borrn">QC验货费</th>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <td class="vd_td_t">
                {{ item.rmb_lumpsum_fee }}
              </td>
              <td class="vd_td_t">
                {{ item.agency_fee }}
              </td>
              <td class="vd_td_t">
                {{ item.gross_profit_two }}
              </td>
              <td class="vd_td_t">
                {{ item.gross_profit_margin }}
              </td>
              <td class="vd_td_t">
                {{ item.exchange_cost }}
              </td>
              <td class="vd_td_t">
                {{ item.purchase_service_fee }}
              </td>
              <td class="vd_td_t vd_borrn">
                {{ item.qc_inspection_fee }}
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { stisAPI } from '@api/modules/stis';
import printJS from 'print-js';
import htmlToExcel from '@/plugins/htmlToExcel';
import CoreList from './CoreList.vue';
import PareList from './PareList.vue';
import PacoList from './PacoList.vue';
import PaprList from './PaprList.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { operationTableProperties, tableProperties } from './ofte';
import { keep1Decimal } from '@assets/js/regExUtil';

export default {
  name: 'OfteList',
  components: {
    SearchTable,
    CoreList,
    PareList,
    PacoList,
    PaprList
  },
  data() {
    return {
      operationDialogVisible: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      dateRange: [],
      tableProperties: tableProperties,
      operationTableProperties: operationTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: false,
      multiSelection: [],
      dialogVisible: false,
      activeName: 'first',
      getIndex: 0,
      CoreList: [],
      PareList: [],
      PacoList: [],
      PaprList: [],
      showFlag: true
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep1Decimal,
    operationRequest(row, status) {
      stisAPI.editOperationStatus({ ...row, operation_status: status }).then(({ data }) => {
        row.operation_status = data;
        this.$message.success('成功!');
      });
    },
    initData() {
      this.getStatsList();
    },
    async getStatsList() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      await getNoCatch(stisAPI.getRpins, searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.getIndex = 0;
        this.loadFlag = false;
      });
    },

    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 打印
    printClick() {
      this.dialogVisible = true;
    },
    printC() {
      const style = '@page {margin:20px 20px;box-sizing: border-box;};'; //打印时去掉眉页眉尾
      //打印为什么要去掉眉页眉尾？因为眉页title时打印当前页面的title，相当于是获取html中title标签里面的内容，但是比如我打印的内容只是一个弹框里面的内容，是没有title的，这时候就会出现undefined，为了避免出现这种情况，就可以隐藏眉页眉尾
      printJS({
        printable: 'printMe', // 标签元素id
        type: 'html',
        header: '',
        targetStyles: ['*'],
        style
      });
    },
    // 导出excel
    exportToExcel(val) {
      htmlToExcel.getExcel(`#multiTable`, '05报表');
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 子表数据接口
    changeSubTable(tran_no, data_sources) {
      if (this.showFlag) {
        this.showFlag = false;
        console.log(this.showFlag);
        getNoCatch(stisAPI.getRpinsFees, {
          tran_no: tran_no,
          data_sources: data_sources
        }).then(({ data }) => {
          this.CoreList = data.list;
          data.list2.forEach(item => {
            item.UUID = crypto.randomUUID();
            item.ppayPartList?.forEach(subItem => {
              subItem.UUID = crypto.randomUUID();
            });
          });
          this.PacoList = data.list2;
          this.PaprList = data.list3;
          this.showFlag = true;
          console.log(this.showFlag);
        });
      }
    },
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 单击每一行更换数据
    prodNoChaange(row, event, column) {
      this.getIndex = row.index;
      this.changeSubTable(row.tran_no, row.data_sources);
    },
    showOperationDialog() {
      if (this.multiSelection.length === 0) return this.$message.warning('至少选择一条数据!');
      this.operationDialogVisible = true;
    },
    confirmModifications() {
      stisAPI.editRpin(this.tableData).then(() => {
        this.getStatsList();
        this.$message.success('修改成功!');
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
table .vd_td_t {
  // width:40%;
  height: 30px;
  padding-left: 3px;
  border-bottom: none;
  // border-right: #e9e9e9 1px solid;
  border-left: none;
  // border-top: #e9e9e9 1px solid;
}
table .vd_td_o {
  width: 20%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}
.vd_borrn {
  border-right: none;
}
.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table {
  // border: #f3f3f3 1px solid;
  // border: none;
  box-sizing: border-box;
  margin-top: 30px;
}
.vd_th {
  text-align: left;
  height: 30px;
  border-top: none;
  border-left: none;
  border-bottom: none;
  color: #909399;
  padding-left: 10px;
  box-sizing: border-box;
}
table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
.printd {
  page-break-after: always;
}
.printc {
  padding: 0 15px;
  box-sizing: border-box;
}
</style>

<template>
  <div>
    <el-row>
      <el-col :md="24">
        <el-table
          border
          stripe
          class="vg_cursor"
          ref="multiTable"
          row-key="UUID"
          :data="tableData"
          :tree-props="{ children: 'ppayPartList', hasChildren: 'hasChildren' }"
          @row-dbclick="dbclick"
        >
          <el-table-column label="出运发票号" prop="tran_no">
            <!--            <template v-slot="scope">
              <span v-if="scope.row.tran_no">
                {{ helper.transverseTranNo(scope.row.tran_no) }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>-->
          </el-table-column>
          <el-table-column label="报关品名" prop="prod_hsname" />
          <el-table-column label="采购合同号" prop="podr_no" />
          <el-table-column label="付款金额" prop="ppay_part_pay" />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CoreList',
  props: {
    tableData: { type: Array, required: true }
  },
  methods: {
    dbclick(row) {
      this.jump('/ppay_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 137,
            form_id: row.ppay_id
          })
        )
      });
    }
  }
};
</script>

<style></style>

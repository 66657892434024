import store from '@/store';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false },
  { label: '最终客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, labelWidth: 140, overflowTooltip: true },
  { label: '报关品名', prop: 'prod_hsnames', itemType: 'input', input: false, sortable: false, labelWidth: 140, overflowTooltip: true },
  // {
  //   label: '数据来源',
  //   prop: 'data_sources',
  //   itemType: 'select',
  //   options: [
  //     { value: 1, label: 'SH' },
  //     { value: 2, label: 'HK' }
  //   ],
  //   input: true,
  //   sortable: false,
  //   formatter: val => (val === 1 ? 'SH' : 'HK')
  // },
  // { label: '收款金额总和', prop: 'total_netrece', itemType: 'input', widthAuto: false },
  { label: '实收外汇(＄)', prop: 'rece_total', itemType: 'input', input: false, sortable: false },
  { label: '应收人民币', prop: 'conv_rmb', itemType: 'input', input: false, sortable: false },
  { label: '佣金率(%)', prop: 'commission_rate', itemType: 'input', input: false, sortable: false },
  { label: '佣金(＄)', prop: 'deduct_comm', itemType: 'input', input: false, sortable: false },
  { label: '扣除佣金后外汇(＄)', prop: 'paid_in_usd', itemType: 'input', input: false, sortable: false },
  { label: '买单汇率', prop: 'purchase_rate', itemType: 'input', input: false, sortable: false },
  { label: '实收人民币', prop: 'paid_in_rmb', itemType: 'input', input: false, sortable: false },
  { label: '工厂货款', prop: 'factory_loan', itemType: 'input', input: false, sortable: false },
  { label: '退税额', prop: 'tax_refund', itemType: 'input', input: false, sortable: false },
  { label: '毛利1', prop: 'gross_profit_one', itemType: 'input', input: false, sortable: false },
  { label: '代理费(￥)', prop: 'agency_fee', itemType: 'input', input: false, sortable: false },
  { label: '毛利2', prop: 'gross_profit_two', itemType: 'input', input: false, sortable: false },
  { label: '包干费(￥)', prop: 'rmb_lumpsum_fee', itemType: 'input', input: false, sortable: false },
  { label: '毛利率', prop: 'gross_profit_margin', itemType: 'input', input: false, sortable: false },
  { label: '换汇成本', prop: 'exchange_cost', itemType: 'input', input: false, sortable: false },
  { label: '采购服务费', prop: 'purchase_service_fee', itemType: 'input', input: false, sortable: false },
  { label: 'QC验货费', prop: 'qc_inspection_fee', itemType: 'input', input: false, sortable: false },
  {
    label: '保存状态',
    prop: 'operation_status',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => (val === 1 ? '已保存' : '未保存')
  }
];
export const operationTableProperties = [
  store.state.index,
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户名称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '报关品名', prop: 'prod_hsnames', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '应收金额', prop: 'tran_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '结算月份',
    prop: 'end_month',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'month'
    }
  },
  { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
